import React from "react"
//import loadable from '@loadable/component'
import Layout from "../components/Layout"
import { Container, Paper,  Typography, Card, CardContent, CardMedia} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import aularioFoto from '../images/aulario.jpg'


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "80px",
  },
  outerIframe: {
    maxWidth: 600,
    maxHeight: 400,
    overflow: "hidden",
    marginLeft: "auto ",
    marginRight: "auto ",
    marginBottom: 10,
  },
  innerIframe: {
    position: "relative",
    height: 0,
    "-webkit-overflow-scrolling": "touch",
    overflow: "auto",
    paddingBottom: "100%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: "none",
  },
  card: {
    display: "flex",
    maxWidth:600,
    marginLeft:'auto',
    marginRight:'auto'
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
   width:'50%'
  },
  cover: {
    width: '50%',
  },

}))

/*export const pageQuery = graphql`
  query {
    headerImg: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`*/

export default function Mapa(props) {
  const classes = useStyles()

  return (
    <Layout>
      <Container className={classes.root} maxWidth="lg">
        <Paper style={{ padding: 10, background: "#f7b032" }}>
          <Typography align="center" variant="h4"style={{color:"#333",fontWeight:'bold'}} gutterBottom>
            Lugar del evento
          </Typography>
          <div className={classes.outerIframe}>
            <div className={classes.innerIframe}>
              <iframe
                id="mapaFrame"
                title="Locacion 7 Coloquio"
                className={classes.iframe}
                frameBorder="0"
                allowFullScreen=""
                width="600"
                height="450"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3034.8927606056986!2d-56.167219135681776!3d-34.91748090771103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f81a27b71199f%3A0x4ece30db435561f!2sEdificio%20Polifuncional%20%22Jose%20Luis%20Massera%22%2C%20Senda%20Nelson%20Landoni%20631%2C%2011200%20Montevideo%2C%20Departamento%20de%20Montevideo!5e0!3m2!1ses-419!2suy!4v1574988264726!5m2!1ses-419!2suy"
              ></iframe>
            </div>
          </div>
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                Edificio Polifunciona "José Luis Massera"
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                Senda Nelson Landoni 631, Montevideo
              </Typography>
            </CardContent>

            <CardMedia
              className={classes.cover}
              image={aularioFoto}
              title="Live from space album cover"
            />
          </Card>
        </Paper>
      </Container>
    </Layout>
  )
}
;
